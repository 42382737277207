<template>
    <div
      class="justify-center items-center flex w-full flex-col pt-5 pb-24 px-10 max-md:max-w-full "
    >
      <div
        class="self-center flex w-full max-w-[1000px] flex-col max-md:max-w-full"
      >
        
        <div
          class="bg-neutral-400 w-full h-px mt-9 max-md:max-w-full"
        ></div>
        <div
          class="items-start flex mt-8 gap-5 self-start"
        >
          <router-link to="/terms/service" class="text-neutral-700 text-sm whitespace-nowrap">Terms & Conditions</router-link>
          <router-link to="/terms/privacy" class="text-neutral-700 text-sm  whitespace-nowrap">Privacy Policy</router-link>
        </div>

      </div>
    </div>
  </template>