<template>


    <div class="min-h-screen bg-[#F5FAF9] flex flex-col">
  
      <tHeaderAuth />
  
      <!-- <div style="font-size: 5px; font-weight: bold;" @click="copyToClipboard">{{ currentUser.accessToken }}</div> -->
      <!-- {{currentUser.accessToken.substring(0, 20)}} ... {{currentUser.accessToken.substr(currentUser.accessToken.length - 20)}} -->
  
      <div class="w-full max-w-[1000px] my-6 max-md:max-w-full bg-white rounded-3xl shadow-md mx-auto overflow-hidden">
    
        <router-view />
                 
      </div>
  
      <tFooter />
  
    </div>
    </template>
  
  <script setup>
  import tHeaderAuth from '@/components/tHeaderAuth.vue'
  import tFooter from '@/components/tFooter.vue'
  </script>

<script>

export default {
  name: 'Profile',
  computed: {
    currentUser() {
      return this.$store.state.auth.user;
    }
  },
  mounted() {
    if (!this.currentUser) {
      this.$router.push('/login');
    }
  },
  methods: {
  copyToClipboard() {
    navigator.clipboard.writeText(this.currentUser.accessToken)
      .then(() => {
        // Handle successful copy
        console.log('Text copied to clipboard');
      })
      .catch(err => {
        // Handle error
        console.error('Failed to copy text: ', err);
      });
  },
  // other methods...
},
};
</script>