import { createI18n } from 'vue-i18n'

const messages = {
  en: {

    balance: 'Balance',
    checking: 'Checking',
    buy: 'Buy',
    price: 'Price',
    Saving: 'Saving',
    Save: 'Save',
    Return: 'Return',

    Menu: {
        Tokens: 'Tokens',
        Transactions: 'Transactions',
        Profile: 'Profile',
        Info: 'Information',
        Users: 'Users',
        Refs: 'Referrals',
    },

    TokenBuy: {
        buy_token: 'BUY TOKENS',
        buy_amount: 'AMOUNT',
        send: 'SEND',
        network: 'NETWORK',
        to: 'TO',
        wait: 'WAIT FOR CONFIRMATION',
        check: 'CHECK',
        sec: 'sec.',
        check_again: 'CHECK AGAIN IN',
        check_success: 'Funds Arrived',
    },
    TokenInfo: {
        balance: 'BALANCE',
        mintDate: 'MINT DATE',
        daysLeft: 'Days Left',
        lockPeriod: 'Lock Period',
        days: 'Days',
        buyTokens: 'Buy Tokens',
        afterMint: 'after mint',
        refillBalance: 'Refill Balance',
        cryptocurrency: 'Cryptocurrency',
        start: 'DISTRIBUTION START',
        end: 'DISTRIBUTION END',
        readmore: 'Read more how to install Web3 digital wallet.',
        bankcard: 'Bank Card',
        cashdep: 'Cash Deposit',
    },

    TokenList: {
        tokens: 'Tokens',
        tokens_no: "You don't have access to any tokens yet.",
        tokens_do: 'Please fill your data and wait invitations to join token groups.',
        profile_data: 'Profile Data',
        has_code: 'In case you have an access code please enter it below:',
        code_wrong: 'Incorrect code.',
        tokens_get: 'Get Access',
        date_srart: 'Start date',
        end_period: 'End period',
    },
    Trans: {
        tx: 'Transactions',
        tx_no: 'No transactions available.',
        Date: 'Date',
        Token: 'Token',
        Amount: 'Amount',
        Name: 'Token Name',
        Type: 'Type',
        Status: 'Status',
        Info: 'Details',
        Scan: 'Blockscan',

    },
    Refs: {
        Referrals: 'Referrals',
        Info: 'Use referral codes to invite friends. After each code is activated a new one is added.',
        Codes: 'Codes',
        Every: 'Every activated code gives you another new code.',
        Sum: 'Sum',
        Purchased: 'Purchased',
        Send: 'Send this code to friends to allow them access the platform:',
        Noacc: 'You do not have access. Please fill the form and wait for being whitelisted.',
        refCodes: 'Referral Codes',
        Activated: 'Activated',
        Earned: 'Earned from Codes',

    },
    Info: {
        general_head: 'Token Mint',
        general_text: 'Tokens are a fundamental concept in blockchain technology and are often used to represent digital assets or currencies. Minting tokens refers to the process of creating new tokens and adding them to a token supply.',
        wallet_head: 'Install Web3 Digital Wallet',
        wallet_text: 'In order to buy tokens you need to install Web3 digital wallet. We recommend to use MetaMask. It is a browser extension that allows you to interact with Ethereum blockchain. It is available for Chrome, Firefox, Opera, and Brave browsers. You can install it from the official website.',
    },
    Profile: {
        Created: 'Account Created',
        Details: 'Details',
        Data: 'Personal Data',
        Country: 'Country of Residence',
        Address: 'Address',
        Phone: 'Phone Number',
        Fill: 'Fill Data',
        Password: 'Password',
        PasswordLast: 'Last Changed',
        Change: 'Change',
        Wallet: 'Wallet',
        WalletNo: 'No wallet to receive tokens.',
        WalletSet: 'Set Wallet',
        Refs: 'Ref. codes',
        RefCom: 'Referrals Comission',
        Name: 'Name',
        Mid: 'Mid Name',
        Surname: 'Surname',
        Nationality: 'Nationality',
        Country: 'Country of Residence',
        National: 'National ID',
        City: 'City',
        ZIP: 'ZIP',
        Phone: 'Phone',
        Data: 'Profile Data',
        Saved: 'Data saved!',
        WalletAddress: 'Wallet Address',
        WalletInfo: 'EVM Capatible Digital wallet to receive tokens.',
        ChangePass: 'Change Password',
        ConPass: 'Confirm Password',
        NewPass: 'New Password',
        CurPass: 'Current Password',
    },

    and: 'and',
    TermsService: 'Terms & Conditions',
    TermsPrivacy: 'Privacy Policy',
    Continue: 'Continue',
    Password: 'Password',
    PasswordRepeat: 'Repeat Password',
    PasswordStrength: 'Password strength',
    Home: {
        Header: 'Explore Universe of Web3 Assets',
        Text: 'Discover the next level of digital assets management with Mintage &mdash; the innovative Web3 tokenization platform.',
        Start: 'Start',
        TextSub: 'Focused on robust security and seamless usability, we offer a multi-chain experience that redefines how you engage with digital assets in Web3 era.',
        L1: 'List your asset',
        L2: 'Get in touch',
    },
    Sign: {
        InHead: 'Login Your Account',
        InStart: 'Enter E-mail address:',
        In: 'Sign In',
        NoIn: 'No account?',
        Up: 'Sign Up',
        Agree: 'By continuing you agree to',
        InTextHead: 'Welcome to Mintage',
        InText: 'Use your password and email to sign in. Never tell your words from the wallet to anyone.',
        InPass: 'Set a Strong Password',
        InPass1: 'Use latin letters and numbers',
        InPass2: 'At least one special character',
        InPass3: 'At least one uppercase letter',
        InPass4: 'Does not end with a number',
        ChangeMail: 'Change E-mail',
        LoginFor: 'Login for ',
        UpStart: 'Create New Account',
        UpNo: 'Already have an account?',
        NewFor: 'New Account for',
        PasswordFormat: 'Please use uppercase and lower letters, numbers and special characters.',
        NoMatch: 'Passwords do not match',
        UpText: 'Start Your Journey Today',
        UpText1: 'Create your digital wallet',
        UpText2: 'Acquire fresh mint offers',
        UpText3: 'Manage your portfolio',
        UpText4: 'Trade and provide liquidity',
        MailFormat: 'Invalid E-mail format',
    },


  },
  de: {
    balance: 'Kontostand',
    checking: 'Kontoprüfung',
    buy: 'Kaufen',
    price: 'Preis',
    Saving: 'Sparen',
    Save: 'Speichern',
    Return: 'Zurück',
    
    Menu: {
        Tokens: 'Token',
        Transactions: 'Transaktionen',
        Profile: 'Profil',
        Info: 'Informationen',
        Users: 'Benutzer',
        Refs: 'Empfehlungen',
    },
    TokenBuy: {
        buy_token: 'TOKEN KAUFEN',
        buy_amount: 'MENGE',
        send: 'SENDEN',
        network: 'NETZWERK',
        to: 'AN',
        wait: 'WARTEN AUF BESTÄTIGUNG',
        check: 'PRÜFEN',
        sec: 'Sek.',
        check_again: 'NOCHMALS PRÜFEN IN',
        check_success: 'Mittel Eingetroffen',
    },
    TokenInfo: {
        balance: 'KONTOSTAND',
        mintDate: 'PRÄGEDATUM',
        daysLeft: 'Verbleibende Tage',
        lockPeriod: 'Sperrfrist',
        days: 'Tage',
        buyTokens: 'Token Kaufen',
        afterMint: 'nach dem Prägen',
        refillBalance: 'Kontostand Aufladen',
        cryptocurrency: 'Kryptowährung',
        start: 'VERTEILUNGSBEGINN',
        end: 'VERTEILUNGSENDE',
        readmore: 'Mehr darüber lesen, wie man eine Web3 digitale Geldbörse installiert.',
        bankcard: 'Bankkarte',
        cashdep: 'Bargeldeinzahlung',
    },

    TokenList: {
        tokens: 'Token',
        tokens_no: "Sie haben noch keinen Zugang zu Token.",
        tokens_do: 'Bitte füllen Sie Ihre Daten aus und warten Sie auf Einladungen, um Token-Gruppen beizutreten.',
        profile_data: 'Profildaten',
        has_code: 'Falls Sie einen Zugangscode haben, geben Sie ihn bitte unten ein:',
        code_wrong: 'Falscher Code.',
        tokens_get: 'Zugang Erhalten',
        date_srart: 'Startdatum',
        end_period: 'Endzeitraum',
    },
    Trans: {
        tx: 'Transaktionen',
        tx_no: 'Keine Transaktionen verfügbar.',
        Date: 'Datum',
        Token: 'Token',
        Amount: 'Betrag',
        Name: 'Token-Name',
        Type: 'Typ',
        Status: 'Status',
        Info: 'Details',
        Scan: 'Blockscan',
    },
    Refs: {
        Referrals: 'Empfehlungen',
        Info: 'Verwenden Sie Empfehlungscodes, um Freunde einzuladen. Nach jeder Aktivierung wird ein neuer Code hinzugefügt.',
        Codes: 'Codes',
        Every: 'Jeder aktivierte Code gibt Ihnen einen weiteren neuen Code.',
        Sum: 'Summe',
        Purchased: 'Gekauft',
        Send: 'Senden Sie diesen Code an Freunde, um ihnen den Zugang zur Plattform zu ermöglichen:',
        Noacc: 'Sie haben keinen Zugang. Bitte füllen Sie das Formular aus und warten Sie darauf, auf die Whitelist gesetzt zu werden.',
        refCodes: 'Empfehlungscodes',
        Activated: 'Aktiviert',
        Earned: 'Verdient durch Codes',
    },
    Info: {
        general_head: 'Token Prägung',
        general_text: 'Token sind ein grundlegendes Konzept in der Blockchain-Technologie und werden oft verwendet, um digitale Vermögenswerte oder Währungen darzustellen. Das Prägen von Token bezieht sich auf den Prozess der Erstellung neuer Token und deren Hinzufügung zur Token-Versorgung.',
        wallet_head: 'Installieren Sie die digitale Web3-Geldbörse',
        wallet_text: 'Um Token zu kaufen, müssen Sie eine digitale Web3-Geldbörse installieren. Wir empfehlen die Verwendung von MetaMask. Es ist eine Browser-Erweiterung, die es Ihnen ermöglicht, mit der Ethereum-Blockchain zu interagieren. Es ist verfügbar für Chrome, Firefox, Opera und Brave Browser. Sie können es von der offiziellen Website installieren.',
    },
    Profile: {
        Created: 'Konto Erstellt',
        Details: 'Details',
        Data: 'Persönliche Daten',
        Country: 'Wohnsitzland',
        Address: 'Adresse',
        Phone: 'Telefonnummer',
        Fill: 'Daten Ausfüllen',
        Password: 'Passwort',
        PasswordLast: 'Zuletzt Geändert',
        Change: 'Ändern',
        Wallet: 'Geldbörse',
        WalletNo: 'Keine Geldbörse, um Token zu erhalten.',
        WalletSet: 'Geldbörse Einrichten',
        Refs: 'Ref.-Codes',
        RefCom: 'Empfehlungskommission',
        Name: 'Name',
        Mid: 'Zweiter Vorname',
        Surname: 'Nachname',
        Nationality: 'Staatsangehörigkeit',
        Country: 'Wohnsitzland',
        National: 'Personalausweis',
        City: 'Stadt',
        ZIP: 'PLZ',
        Phone: 'Telefon',
        Data: 'Profildaten',
        Saved: 'Daten gespeichert!',
        WalletAddress: 'Geldbörsenadresse',
        WalletInfo: 'EVM-kompatible digitale Geldbörse, um Token zu erhalten.',
        ChangePass: 'Passwort Ändern',
        ConPass: 'Passwort Bestätigen',
        NewPass: 'Neues Passwort',
        CurPass: 'Aktuelles Passwort',
    },
    and: 'und',
    TermsService: 'Allgemeine Geschäftsbedingungen',
    TermsPrivacy: 'Datenschutzbestimmungen',
    Continue: 'Fortsetzen',
    Password: 'Passwort',
    PasswordRepeat: 'Passwort wiederholen',
    PasswordStrength: 'Passwortstärke',
    Home: {
        Header: 'Erforschen Sie das Universum der Web3-Vermögenswerte',
        Text: 'Entdecken Sie die nächste Ebene des digitalen Vermögensmanagements mit Mintage &mdash; der innovativen Web3-Tokenisierungsplattform.',
        Start: 'Starten',
        TextSub: 'Mit Fokus auf robuster Sicherheit und nahtloser Benutzerfreundlichkeit bieten wir ein Multi-Chain-Erlebnis, das Ihre Interaktion mit digitalen Vermögenswerten im Web3-Zeitalter neu definiert.',
        L1: 'Listen Sie Ihr Vermögen auf',
        L2: 'Nehmen Sie Kontakt auf',
    },
    Sign: {
        InHead: 'Melden Sie sich in Ihrem Konto an',
        InStart: 'E-Mail-Adresse eingeben:',
        In: 'Anmelden',
        NoIn: 'Kein Konto?',
        Up: 'Registrieren',
        Agree: 'Indem Sie fortfahren, stimmen Sie zu',
        InTextHead: 'Willkommen bei Mintage',
        InText: 'Verwenden Sie Ihr Passwort und Ihre E-Mail, um sich anzumelden. Geben Sie niemals Ihre Wallet-Wörter an jemanden weiter.',
        InPass: 'Setzen Sie ein starkes Passwort',
        InPass1: 'Verwenden Sie lateinische Buchstaben und Zahlen',
        InPass2: 'Mindestens ein Sonderzeichen',
        InPass3: 'Mindestens ein Großbuchstabe',
        InPass4: 'Endet nicht mit einer Zahl',
        ChangeMail: 'E-Mail ändern',
        LoginFor: 'Anmeldung für ',
        UpStart: 'Neues Konto erstellen',
        UpNo: 'Sie haben bereits ein Konto?',
        NewFor: 'Neues Konto für',
        PasswordFormat: 'Bitte verwenden Sie Groß- und Kleinbuchstaben, Zahlen und Sonderzeichen.',
        NoMatch: 'Passwörter stimmen nicht überein',
        UpText: 'Beginnen Sie Ihre Reise heute',
        UpText1: 'Erstellen Sie Ihre digitale Geldbörse',
        UpText2: 'Erhalten Sie frische Münzangebote',
        UpText3: 'Verwalten Sie Ihr Portfolio',
        UpText4: 'Handeln und Liquidität bereitstellen',
        MailFormat: 'Ungültiges E-Mail-Format',
    },


  },
  fr: {

    balance: 'Solde',
    checking: 'Vérification',
    buy: 'Acheter',
    price: 'Prix',
    Saving: 'Épargne',
    Save: 'Sauvegarder',
    Return: 'Retour',
    
    Menu: {
        Tokens: 'Jetons',
        Transactions: 'Transactions',
        Profile: 'Profil',
        Info: 'Information',
        Users: 'Utilisateurs',
        Refs: 'Parrainages',
    },
    
    TokenBuy: {
        buy_token: 'ACHETER DES JETONS',
        buy_amount: 'MONTANT',
        send: 'ENVOYER',
        network: 'RÉSEAU',
        to: 'À',
        wait: 'ATTENDRE LA CONFIRMATION',
        check: 'VÉRIFIER',
        sec: 'sec.',
        check_again: 'VÉRIFIER DE NOUVEAU DANS',
        check_success: 'Fonds Arrivés',
    },
    TokenInfo: {
        balance: 'SOLDE',
        mintDate: 'DATE DE FRAPPE',
        daysLeft: 'Jours Restants',
        lockPeriod: 'Période de Blocage',
        days: 'Jours',
        buyTokens: 'Acheter des Jetons',
        afterMint: 'après la frappe',
        refillBalance: 'Recharger le Solde',
        cryptocurrency: 'Cryptomonnaie',
        start: 'DÉBUT DE LA DISTRIBUTION',
        end: 'FIN DE LA DISTRIBUTION',
        readmore: 'En savoir plus sur l’installation du portefeuille numérique Web3.',
        bankcard: 'Carte Bancaire',
        cashdep: 'Dépôt en Espèces',
    },
    TokenList: {
        tokens: 'Jetons',
        tokens_no: "Vous n'avez pas encore accès à des jetons.",
        tokens_do: 'Veuillez remplir vos données et attendre les invitations pour rejoindre des groupes de jetons.',
        profile_data: 'Données du Profil',
        has_code: 'Si vous avez un code d’accès, veuillez le saisir ci-dessous :',
        code_wrong: 'Code incorrect.',
        tokens_get: 'Obtenir l’Accès',
        date_srart: 'Date de début',
        end_period: 'Fin de période',
    },
    Trans: {
        tx: 'Transactions',
        tx_no: 'Aucune transaction disponible.',
        Date: 'Date',
        Token: 'Jeton',
        Amount: 'Montant',
        Name: 'Nom du Jeton',
        Type: 'Type',
        Status: 'Statut',
        Info: 'Détails',
        Scan: 'Blockscan',  
    } , 
    Refs: {
        Referrals: 'Parrainages',
        Info: 'Utilisez des codes de parrainage pour inviter des amis. Après chaque activation d\'un code, un nouveau est ajouté.',
        Codes: 'Codes',
        Every: 'Chaque code activé vous donne un nouveau code.',
        Sum: 'Somme',
        Purchased: 'Acheté',
        Send: 'Envoyez ce code à des amis pour leur permettre d\'accéder à la plateforme :',
        Noacc: 'Vous n\'avez pas accès. Veuillez remplir le formulaire et attendre d\'être ajouté à la liste blanche.',
        refCodes: 'Codes de Parrainage',
        Activated: 'Activé',
        Earned: 'Gagné grâce aux Codes',
    },
    Info: {
        general_head: 'Création de Token',
        general_text: 'Les tokens sont un concept fondamental dans la technologie blockchain et sont souvent utilisés pour représenter des actifs numériques ou des devises. La création de tokens se réfère au processus de création de nouveaux tokens et de leur ajout à l\'offre de tokens.',
        wallet_head: 'Installer un Portefeuille Numérique Web3',
        wallet_text: 'Pour acheter des tokens, vous devez installer un portefeuille numérique Web3. Nous recommandons d\'utiliser MetaMask. C\'est une extension de navigateur qui vous permet d\'interagir avec la blockchain Ethereum. Disponible pour les navigateurs Chrome, Firefox, Opera et Brave. Vous pouvez l\'installer depuis le site officiel.',
    },
    Profile: {
        Created: 'Compte Créé',
        Details: 'Détails',
        Data: 'Données Personnelles',
        Country: 'Pays de Résidence',
        Address: 'Adresse',
        Phone: 'Numéro de Téléphone',
        Fill: 'Remplir les Données',
        Password: 'Mot de Passe',
        PasswordLast: 'Dernière Modification',
        Change: 'Modifier',
        Wallet: 'Portefeuille',
        WalletNo: 'Aucun portefeuille pour recevoir des tokens.',
        WalletSet: 'Configurer le Portefeuille',
        Refs: 'Codes de Parrainage',
        RefCom: 'Commission de Parrainage',
        Name: 'Nom',
        Mid: 'Deuxième Prénom',
        Surname: 'Nom de Famille',
        Nationality: 'Nationalité',
        Country: 'Pays de Résidence',
        National: 'ID National',
        City: 'Ville',
        ZIP: 'Code Postal',
        Phone: 'Téléphone',
        Data: 'Données du Profil',
        Saved: 'Données Enregistrées !',
        WalletAddress: 'Adresse du Portefeuille',
        WalletInfo: 'Portefeuille Numérique EVM compatible pour recevoir des tokens.',
        ChangePass: 'Changer le Mot de Passe',
        ConPass: 'Confirmer le Mot de Passe',
        NewPass: 'Nouveau Mot de Passe',
        CurPass: 'Mot de Passe Actuel',
    },
    and: 'et',
    TermsService: 'Conditions générales',
    TermsPrivacy: 'Politique de confidentialité',
    Continue: 'Continuer',
    Password: 'Mot de passe',
    PasswordRepeat: 'Répéter le mot de passe',
    PasswordStrength: 'Force du mot de passe',
    Home: {
        Header: 'Explorez l’univers des actifs Web3',
        Text: 'Découvrez le niveau suivant de la gestion des actifs numériques avec Mintage &mdash; la plateforme innovante de tokenisation Web3.',
        Start: 'Commencer',
        TextSub: 'Axé sur une sécurité robuste et une facilité d’utilisation sans faille, nous offrons une expérience multi-chaînes qui redéfinit votre engagement avec les actifs numériques à l’ère Web3.',
        L1: 'Listez votre actif',
        L2: 'Prenez contact',
    },
    Sign: {
        InHead: 'Connectez-vous à votre compte',
        InStart: 'Entrez l’adresse e-mail :',
        In: 'Se connecter',
        NoIn: 'Pas de compte ?',
        Up: 'S’inscrire',
        Agree: 'En continuant, vous acceptez',
        InTextHead: 'Bienvenue chez Mintage',
        InText: 'Utilisez votre mot de passe et votre e-mail pour vous connecter. Ne révélez jamais les mots de votre portefeuille à quiconque.',
        InPass: 'Définissez un mot de passe fort',
        InPass1: 'Utilisez des lettres latines et des chiffres',
        InPass2: 'Au moins un caractère spécial',
        InPass3: 'Au moins une lettre majuscule',
        InPass4: 'Ne se termine pas par un chiffre',
        ChangeMail: 'Changer d’e-mail',
        LoginFor: 'Connexion pour',
        UpStart: 'Créer un nouveau compte',
        UpNo: 'Vous avez déjà un compte ?',
        NewFor: 'Nouveau compte pour',
        PasswordFormat: 'Veuillez utiliser des lettres majuscules et minuscules, des chiffres et des caractères spéciaux.',
        NoMatch: 'Les mots de passe ne correspondent pas',
        UpText: 'Commencez votre voyage aujourd’hui',
        UpText1: 'Créez votre portefeuille numérique',
        UpText2: 'Obtenez des offres de frappe fraîches',
        UpText3: 'Gérez votre portefeuille',
        UpText4: 'Commercez et fournissez de la liquidité',
        MailFormat: 'Format d’e-mail invalide',
    },


  },
  cn: {
    balance: '余额',
    checking: '检查中',
    buy: '购买',
    price: '价格',
    Saving: '储蓄',
    Save: '保存',
    Return: '返回',
    
    Menu: {
        Tokens: '代币',
        Transactions: '交易',
        Profile: '个人资料',
        Info: '信息',
        Users: '用户',
        Refs: '推荐',
    },
    
    TokenBuy: {
        buy_token: '购买代币',
        buy_amount: '数量',
        send: '发送',
        network: '网络',
        to: '到',
        wait: '等待确认',
        check: '检查',
        sec: '秒',
        check_again: '再次检查',
        check_success: '资金已到账',
    },
    TokenInfo: {
        balance: '余额',
        mintDate: '铸币日期',
        daysLeft: '剩余天数',
        lockPeriod: '锁定期',
        days: '天数',
        buyTokens: '购买代币',
        afterMint: '铸币后',
        refillBalance: '补充余额',
        cryptocurrency: '加密货币',
        start: '分发开始',
        end: '分发结束',
        readmore: '了解更多关于安装Web3数字钱包的信息。',
        bankcard: '银行卡',
        cashdep: '现金存款',
    },
    TokenList: {
        tokens: '代币',
        tokens_no: "您还没有代币访问权限。",
        tokens_do: '请填写您的数据并等待邀请加入代币组。',
        profile_data: '个人资料数据',
        has_code: '如果您有访问代码，请在下面输入：',
        code_wrong: '错误的代码。',
        tokens_get: '获得访问权限',
        date_srart: '开始日期',
        end_period: '结束时期',
    },
    Trans: {
        tx: '交易',
        tx_no: '没有可用的交易。',
        Date: '日期',
        Token: '代币',
        Amount: '金额',
        Name: '代币名称',
        Type: '类型',
        Status: '状态',
        Info: '详情',
        Scan: '区块扫描',
    },
    Refs: {
        Referrals: '推荐',
        Info: '使用推荐码邀请朋友。每激活一个代码后，将添加一个新的。',
        Codes: '代码',
        Every: '每个激活的代码都会给您一个新的代码。',
        Sum: '总和',
        Purchased: '已购买',
        Send: '将此代码发送给朋友，以便他们访问平台：',
        Noacc: '您没有访问权限。请填写表格并等待被列入白名单。',
        refCodes: '推荐代码',
        Activated: '已激活',
        Earned: '通过代码赚取',
    },
    Info: {
        general_head: '代币铸造',
        general_text: '代币是区块链技术的基本概念，常用于代表数字资产或货币。代币铸造是指创建新代币并将其添加到代币供应中的过程。',
        wallet_head: '安装数字Web3钱包',
        wallet_text: '要购买代币，您需要安装数字Web3钱包。我们推荐使用MetaMask。它是一个浏览器扩展，允许您与以太坊区块链互动。适用于Chrome、Firefox、Opera和Brave浏览器。您可以从官方网站安装。',
    },
    Profile: {
        Created: '账户已创建',
        Details: '详情',
        Data: '个人数据',
        Country: '居住国家',
        Address: '地址',
        Phone: '电话号码',
        Fill: '填写数据',
        Password: '密码',
        PasswordLast: '最后更改',
        Change: '更改',
        Wallet: '钱包',
        WalletNo: '没有钱包接收代币。',
        WalletSet: '设置钱包',
        Refs: '推荐码',
        RefCom: '推荐佣金',
        Name: '名字',
        Mid: '中间名',
        Surname: '姓氏',
        Nationality: '国籍',
        Country: '居住国家',
        National: '国家身份证',
        City: '城市',
        ZIP: '邮政编码',
        Phone: '电话',
        Data: '个人资料',
        Saved: '数据已保存！',
        WalletAddress: '钱包地址',
        WalletInfo: 'EVM兼容的数字钱包，用于接收代币。',
        ChangePass: '更改密码',
        ConPass: '确认密码',
        NewPass: '新密码',
        CurPass: '当前密码',
    },
    and: '和',
    TermsService: '服务条款',
    TermsPrivacy: '隐私政策',
    Continue: '继续',
    Password: '密码',
    PasswordRepeat: '重复密码',
    PasswordStrength: '密码强度',
    Home: {
        Header: '探索Web3资产宇宙',
        Text: '通过Mintage 一 创新的Web3代币化平台，发现数字资产管理的下一个层次。',
        Start: '开始',
        TextSub: '专注于强大的安全性和无缝的可用性，我们提供了一种多链体验，重新定义了您在Web3时代与数字资产的互动方式。',
        L1: '列出您的资产',
        L2: '取得联系',
    },
    Sign: {
        InHead: '登录您的账户',
        InStart: '输入电子邮件地址：',
        In: '登录',
        NoIn: '没有账户？',
        Up: '注册',
        Agree: '继续即表示您同意',
        InTextHead: '欢迎来到Mintage',
        InText: '使用您的密码和电子邮件登录。不要将您钱包中的任何词语告诉任何人。',
        InPass: '设置强密码',
        InPass1: '使用拉丁字母和数字',
        InPass2: '至少一个特殊字符',
        InPass3: '至少一个大写字母',
        InPass4: '不以数字结尾',
        ChangeMail: '更改电子邮件',
        LoginFor: '登录 ',
        UpStart: '创建新账户',
        UpNo: '已有账户？',
        NewFor: '新账户 ',
        PasswordFormat: '请使用大写和小写字母、数字和特殊字符。',
        NoMatch: '密码不匹配',
        UpText: '今天开始您的旅程',
        UpText1: '创建您的数字钱包',
        UpText2: '获取新鲜的铸币优惠',
        UpText3: '管理您的投资组合',
        UpText4: '交易和提供流动性',
        MailFormat: '电子邮件格式无效',
    },


  },
  ru: {

    balance: 'Баланс',
    checking: 'Проверка',
    buy: 'Купить',
    price: 'Цена',
    Saving: 'Сохранение',
    Save: 'Сохранить',
    Return: 'Назад',

    Menu: {
        Tokens: 'Токены',
        Transactions: 'Транзакции',
        Profile: 'Профиль',
        Info: 'Информация',
        Users: 'Пользователи',
        Refs: 'Рефералы',
    },
    TokenBuy: {
        buy_token: 'КУПИТЬ ТОКЕНЫ',
        buy_amount: 'КОЛИЧЕСТВО',
        send: 'ОТПРАВЬТЕ',
        network: 'СЕТЬ',
        to: 'НА',
        wait: 'ОЖИДАЙТЕ ПОДТВЕРЖДЕНИЯ',
        check: 'ПРОВЕРИТЬ',
        sec: 'сек.',
        check_again: 'ПРОВЕРИТЬ СНОВА ЧЕРЕЗ',
        check_success: 'Токены Зачислены',
    },
    TokenInfo: {
        balance: 'БАЛАНС',
        mintDate: 'ДАТА МИНТА',
        daysLeft: 'Осталось Дней',
        lockPeriod: 'ПЕРИОД БЛОКИРОВКИ',
        days: 'Дней',
        buyTokens: 'Купить Токены',
        afterMint: 'после минта',
        refillBalance: 'Пополнить Баланс',
        cryptocurrency: 'Криптовалюта',
        start: 'НАЧАЛО ДИСТРИБУЦИИ',
        end: 'КОНЕЦ ДИСТРИБУЦИИ',
        readmore: 'Узнайте больше о том, как установить цифровой кошелек Web3.',
        bankcard: 'Банковская Карта',
        cashdep: 'Депозит Наличными',
    },
    TokenList: {
        tokens: 'Токены',
        tokens_no: "У вас пока нет доступа к токенам.",
        tokens_do: 'Пожалуйста, заполните свои данные и ожидайте приглашения в группы токенов.',
        profile_data: 'Данные Профиля',
        has_code: 'Если у вас есть код доступа, пожалуйста, введите его ниже:',
        code_wrong: 'Неверный код.',
        tokens_get: 'Получить Доступ',
        date_start: 'Старт',
        end_period: 'Завершение',
    },
    Refs: {
            Referrals: 'Рефералы',
            Info: 'Используйте реферальные коды для приглашения друзей. После активации каждого кода добавляется новый.',
            Codes: 'Коды',
            Every: 'Каждый активированный код дает вам еще один новый код.',
            Sum: 'Сумма',
            Purchased: 'Куплено',
            Send: 'Отправьте этот код друзьям, чтобы они могли получить доступ к платформе:',
            Noacc: 'У вас нет доступа. Пожалуйста, заполните форму и ожидайте добавления в белый список.',
            refCodes: 'Реферальные Коды',
            Activated: 'Активировано',
            Earned: 'Заработано с Кодов',
    },
    Trans: {
        tx: 'Транзакции',
        tx_no: 'Транзакций нет.',
        Date: 'Дата',
        Token: 'Токен',
        Amount: 'Сумма',
        Name: 'Название Токена',
        Type: 'Тип',
        Status: 'Статус',
        Info: 'Детали',
        Scan: 'Блокскан',
    },
    Profile: {
        Created: 'Аккаунт Создан',
        Details: 'Детали',
        Data: 'Личные Данные',
        Country: 'Страна Проживания',
        Address: 'Адрес',
        Phone: 'Номер Телефона',
        Fill: 'Заполнить Данные',
        Password: 'Пароль',
        PasswordLast: 'Последнее Изменение',
        Change: 'Изменить',
        Wallet: 'Кошелек',
        WalletNo: 'Нет кошелька для получения токенов.',
        WalletSet: 'Установить Кошелек',
        Refs: 'Реф. коды',
        RefCom: 'Комиссия за Рефералов',
        Name: 'Имя',
        Mid: 'Отчество',
        Surname: 'Фамилия',
        Nationality: 'Национальность',
        Country: 'Страна Проживания',
        National: 'Документ',
        City: 'Город',
        ZIP: 'Индекс',
        Phone: 'Телефон',
        Data: 'Персональная Анкета',
        Saved: 'Данные Сохранены', 
        WalletAddress: 'Адрес кошелька',
        WalletInfo: 'Цифровой EVM совместимый кошелек для начисления токенов.',      
        ChangePass: 'Изменить Пароль',
        ConPass: 'Подтвердить Пароль',
        NewPass: 'Новый Пароль',
        CurPass: 'Текущий Пароль',
    },

    and: 'и',
    TermsService: 'Условия и положения',
    TermsPrivacy: 'Политика конфиденциальности',
    Continue: 'Продолжить',
    Password: 'Пароль',
    PasswordRepeat: 'Повторите пароль',
    Home: {
        Header: 'Исследуйте Вселенную Web3',
        Text: 'Откройте для себя новый уровень управления цифровыми активами.',
        Start: 'Начать',
        TextSub: 'Mintage — инновационнfz платформf токенизации цифровых активов, с особым вниманием на безопасности и простоте использования.',
        L1: 'Разместите ваш актив',
        L2: 'Свяжитесь с нами',
    },
    Sign: {
        InHead: 'Войдите в свой аккаунт',
        InStart: 'Введите адрес электронной почты:',
        In: 'Войти',
        NoIn: 'Нет аккаунта?',
        Up: 'Зарегистрироваться',
        Agree: 'Продолжая, вы соглашаетесь с',
        InTextHead: 'Добро пожаловать в Mintage',
        InText: 'Используйте ваш пароль и электронную почту для входа. Никогда не говорите слова из кошелька никому.',
        InPass: 'Установите надежный пароль',
        InPass1: 'Используйте латинские буквы и цифры',
        InPass2: 'По крайней мере один специальный символ',
        InPass3: 'По крайней мере одна заглавная буква',
        InPass4: 'Не заканчивается на цифру',
        ChangeMail: 'Изменить электронную почту',
        LoginFor: 'Вход для ',
        UpStart: 'Создать новый аккаунт',
        UpNo: 'Уже есть аккаунт?',
        NewFor: 'Новый аккаунт для',
        PasswordFormat: 'Пожалуйста, используйте заглавные и строчные буквы, цифры и специальные символы.',
        NoMatch: 'Пароли не совпадают',
        UpText: 'Начните свое путешествие сегодня',
        UpText1: 'Создайте свой цифровой кошелек',
        UpText2: 'Получите свежие предложения по монетам',
        UpText3: 'Управляйте своим портфелем',
        UpText4: 'Торгуйте и обеспечивайте ликвидность',
        MailFormat: 'Неверный формат адреса',
    },

  }
  
}

const i18n = createI18n({
  locale: 'en', // set locale
  fallbackLocale: 'en', // set fallback locale
  messages, // set locale messages
})

export default i18n