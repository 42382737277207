<template>
  <router-view/>
</template>

<style>

@import "@/assets/main.css";

#app {
  font-family: Avenir, Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}

nav {
  padding: 30px;
}

nav a {
  font-weight: bold;
  color: #2c3e50;
}

nav a.router-link-exact-active {
  color: #42b983;
}

h1 { font-family: 'K2D', sans-serif; font-size:2.5rem !important; font-weight: 500; margin-bottom:0.25rem !important; }
h2 { font-family: 'K2D', sans-serif; font-size:1.89rem !important; font-weight: 700; color:#333; margin-bottom:0.20rem !important; }
h3 { font-family: 'K2D', sans-serif; font-size:1.25rem !important; color:#555; margin-bottom:0.2rem !important; }
h4 { font-family: 'K2D', sans-serif; font-size:1.0rem !important; color:#777; margin-bottom:0.15rem !important; }
h5 { font-family: 'K2D', sans-serif; font-size:0.75rem !important; color:#777;margin-bottom:0.1rem !important; }
.save { display: inline-block; background: #dedede !important; padding:10px 15px !important; border-radius: 8px; }

</style>

<script>
export default {
  mounted() {
    document.title = 'Mintage';
  },
}
</script>
