import axios from 'axios';
import authHeader from './auth-header';

const API_URL = process.env.VUE_APP_API_URL;

class UserService {

/* + 2FA

POST
/auth/restore
Restore password

POST
/auth/restore/new
Set new password

POST
/profile/two-factor/enable
Enable 2FA

POST
/profile/two-factor/disable
Disable 2FA

GET
/profile/two-factor/qr
2FA - get QR code

GET
/auth/google/login

GET
/auth/google/redirect

*/

  // Get list of users
  getAdminBoard() {
    return axios.get(API_URL + 'profile/users', { headers: authHeader() });
  }

  getPublicContent() {
    return axios.get(API_URL + 'all');
  }


  // List of all tokens
  getTokens() {
    return axios.get(API_URL + 'tokens', { headers: authHeader() });
  }
  // List of my tokens
  getMyTokens() {
    return axios.get(API_URL + 'tokens/my', { headers: authHeader() });
  }
  // Get token by ticker
  getToken(tokenId) {
    console.log('getToken', tokenId);
    return axios.get(API_URL + 'tokens/' + tokenId, { headers: authHeader() });
  }
  // Request address
  getTokenAddress(tokenId) {
    return axios.get(API_URL + 'tokens/' + tokenId + '/buy', { headers: authHeader() });
  }
  
  // Buy token by ticker
  // getToken(tokenId) {
  //   return axios.post(API_URL + 'tokens/' + tokenId + '/buy22', { headers: authHeader() });
  // }

  getUserData() {
    return axios.get(API_URL + 'profile', { headers: authHeader() });
  }
  // Save user profile
  saveUserData() {
    return axios.post(API_URL + 'profile', { headers: authHeader() });
  }

  checkWallet(network, address) {
    return axios.post(API_URL + 'wallet', { 
      'network': network, 
      'address': address 
    }, { headers: authHeader() });
  }

  saveWallet(network, address) {
    return axios.post(API_URL + 'profile/address', { 
      'network': network, 
      'address': address 
    }, { headers: authHeader() });
  }

  saveProfileData(profiledata) {
    return axios.post(API_URL + 'profile', { 
      'name': profiledata.name, 
      'surName': profiledata.surName, 
      'middleName': profiledata.middleName, 
      'country': profiledata.country, 
      'nationalId': profiledata.nationalId, 
      'address': profiledata.address, 
      'city': profiledata.city, 
      'zip': Number(profiledata.zip), 
      'phone': profiledata.phone, 
    }, { headers: authHeader() })
    .catch(error => {
      if (error.response && error.response.status === 400) {
        // console.log('Error:', error.response.data.error);
        // console.log('Message:', error.response.data.message);
        // console.log('Status Code:', error.response.data.statusCode);
        return Promise.reject(error.response.data.message);
      }
      return Promise.reject(error.response.status);
    });
  }

  changePassword(password0, password1, password2) {
    return axios.post(API_URL + 'profile', { 
      'password': password1, 
      'password2': password2, 
      'oldpassword': password0, 
    }, { headers: authHeader() })
    .catch(error => {
      if (error.response && error.response.status === 400) {
        // console.log('Error:', error.response.data.error);
        // console.log('Message:', error.response.data.message);
        // console.log('Status Code:', error.response.data.statusCode);
        return Promise.reject(error.response.data.message);
      }
      return Promise.reject(error.response.status);
    });
  }

  // Activate profile by code
  allowUserCode(code) {
    return axios.post(API_URL + 'profile/code', { 
      'code': code,
    }, { headers: authHeader() });
  }
  // List of my codes
  getCodes() {
    return axios.get(API_URL + 'profile/code', { headers: authHeader() });
  }
  // List of ref codes
  getRefCodes() {
    return axios.get(API_URL + '/profile/initial/ref/codes', { headers: authHeader() });
  }

  // List of all transactions
  getTransactions() {
    return axios.get(API_URL + 'profile/transactions', { headers: authHeader() });
  }
  // List of all transactions by ticker
  getTransactionsToken(tokenId) {
    return axios.get(API_URL + 'profile/transactions/' + tokenId + '', { headers: authHeader() });
  }

  getInitialCode() {
    return axios.get(API_URL + 'profile/initial/code', { headers: authHeader() });
  }

  // Allow user access to the platform tokens
  allowUser(userId) {
    return axios.get(API_URL + 'profile/users/' + userId + '/allow', { headers: authHeader() });
  }
  // Deny user access to the platform tokens
  disallowUser(userId) {
    return axios.get(API_URL + 'profile/users/' + userId + '/decline', { headers: authHeader() });
  }

}

export default new UserService();
