import { createApp } from 'vue'
// import { createStore } from 'vuex';
import App from './App.vue'

import router from "./router";
import store from "./store";
import i18n from './i18n'

// import { createRouter, createWebHistory } from 'vue-router'

createApp(App)
  .use(router)
  .use(store)
  .use(i18n)
  .mount('#app');
