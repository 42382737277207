import { createWebHistory, createRouter } from "vue-router";

import Home from '@/views/Home.vue'
import User from '@/views/User.vue'
import Profile from '@/views/Profile.vue'
import Info from '@/views/Info.vue'

// const Profile = () => import("@/views/Profile.vue")
// const BoardAdmin = () => import("@/views/BoardAdmin.vue")
// const BoardUser = () => import("@/views/BoardUser.vue")

const routes = [

    {
      path: '/',
      name: 'home',
      component: Home
    },
    // {
    //   path: "/home",
    //   component: Home,
    // },

    { path: '/admin', name: 'admin', component: () => import('@/views/Admin.vue') },
   
    { path: '/exit', name: 'newPage', component: () => import('@/views/Page.vue') },

    { path: '/signup', name: 'signup', component: () => import('@/views/SignUp.vue') },
    { path: '/signup1', name: 'signup1', component: () => import('@/views/SignUp1.vue'), props: true },
    { path: '/signin', name: 'signin', component: () => import('@/views/SignIn.vue') },
    { path: '/signin1', name: 'signin1', component: () => import('@/views/SignIn1.vue'), props: true },
    { path: '/welcome', name: 'logout', component: () => import('@/views/Welcome.vue') },
    { path: '/signlost', name: 'signlost', component: () => import('@/views/SignLost.vue') },
  
    {
      path: '/cabinet',
      name: 'cabinet',
      component: User,
        children: [
          { path: '', name: 'Details', component: () => import('@/views/UserDetails.vue') },
          { path: 'tokens', name: 'Tokens', component: () => import('@/views/UserTokens.vue') },

          { path: 'transactions', name: 'Transac', component: () => import('@/views/UserTX.vue') },
          { path: 'token/:id', name: 'Token', component: () => import('@/views/UserToken.vue'), props: true },
          { path: 'tokenbuy/:id', name: 'TokenBuy', component: () => import('@/views/UserTokenBuy.vue'), props: true },
          // { path: 'profile', name: 'ProfileData', component: () => import('@/views/UserData.vue') },
          // { path: 'settings', name: 'Settings', component: () => import('@/views/UserSettings.vue') },
        ]
    },

    { path: '/refs', name: 'Refs', component: () => import('@/views/Refs.vue') },

    {
      path: '/info',
      name: 'Info',
      component: Info,
        children: [
          { path: '', name: 'InfoGeneral', component: () => import('@/views/InfoGeneral.vue') },
          { path: 'wallet', name: 'InfoWallet', component: () => import('@/views/InfoWallet.vue') },
        ]
    },   
    {
      path: '/profile',
      name: 'Profile',
      component: Profile,
        children: [
          { path: '', name: 'ProfileSettings', component: () => import('@/views/ProfileSettings.vue') },
          { path: 'password', name: 'ProfilePassword', component: () => import('@/views/ProfilePassword.vue') },
          { path: 'wallet', name: 'ProfileWallet', component: () => import('@/views/ProfileWallet.vue') },
          { path: 'data', name: 'ProfileData', component: () => import('@/views/ProfileData.vue') },
        ]
    },   
    
    { path: '/terms/service', name: 'terms', component: () => import('@/views/TermsService.vue') },
    { path: '/terms/privacy', name: 'privacy', component: () => import('@/views/TermsPrivacy.vue') },    

    // 2fa { path: '/signin2', name: 'signin2', component: () => import('@/views/SignIn2.vue') },
    // { path: '/signlost2fa', name: 'signlost2fa', component: () => import('@/views/SignLost2FA.vue') },
  
    // {
    //   path: '/:catchAll(.*)*',
    //   name: "Error",
    //   component: Error,
    // },
    
  ]

  const router = createRouter({
    history: createWebHistory(),
    routes,
  });
  
  router.beforeEach((to, from, next) => {
    const publicPages = ['/', '/signin', '/signin1', '/signup', '/signup1', '/logout' ]; //  '/home'
    const authRequired = !publicPages.includes(to.path);
    const loggedIn = localStorage.getItem('user');
    // trying to access a restricted page + not logged in
    // redirect to login page
    if (authRequired && !loggedIn) {
      next('/signin');
    } else {
      next();
    }
  });
  
  export default router;