<template>
    <div
      class="md:h-18 w-full 
      bg-[#FFF2D1] border-b border-b-[#C7C1B2] 
      flex flex-col md:flex-row px-10 py-4 max-md:max-w-full max-md:px-5"
    >
      <div
        class="w-full flex flex-row
        max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:pr-2 "
      >
        <router-link to="/"><img
          loading="lazy"
          src="/static/mintage.png"
          class="w-[160px] my-auto max-md:w-[140px]"
        /></router-link>

        <div
          class="w-[65px] my-auto ml-auto"
        >

          <div class="relative my-auto" v-on:mouseleave="showModal = false" v-on:touchend="toggleModal" ref="modal">
            <div class="text-xl cursor-pointer whitespace-nowrap my-auto border border-gray-700 rounded-lg px-4 py-2" v-on:mouseover="showModal = true"><span class="uppercase">{{ this.$i18n.locale }}</span></div>
            <div class="absolute right-0 top-full w-48 rounded-xl shadow-xl bg-white z-50 text-left" v-show="showModal">
              <div class="p-3" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
                <button class="px-5 py-2" v-for="(language, index) in languages" :key="index" @click="changeLanguage(language.locale)">
                  <img :src="language.flag" class="inline-block mr-3 rounded-full border-2 border-neutral-500">
                  <span>{{ language.name }}</span>
                </button>
              </div>
            </div>
          </div>

      </div>
    </div>

  </div>
  </template>

<script>
export default {
  data() {
    return {
      showModal: false,
      languages: [
        { name: 'English', locale: 'en', flag: '/static/flags/gb-eng.png' },
        { name: 'German', locale: 'de', flag: '/static/flags/de.png' },
        { name: 'French', locale: 'fr', flag: '/static/flags/fr.png' },
        { name: 'Russian', locale: 'ru', flag: '/static/flags/ru.png' },
        { name: 'Chinese', locale: 'cn', flag: '/static/flags/cn.png' },
      ],
    };
  },
  mounted() {

    this.$i18n.locale = localStorage.getItem('locale') || 'cn';
  },
  methods: {
    changeLanguage(languageCode) {
      this.$i18n.locale = languageCode;
      localStorage.setItem('locale', languageCode);
      this.showModal=false;
    },
    closeModalIfOutsideModal(event) {
      if (!this.$refs.modal.contains(event.target)) {
        this.showModal = false;
      }
    },
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
  },
};
</script>