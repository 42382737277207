<template>
  <div
    class="md:h-18 justify-center items-center self-stretch 
    bg-[#FFF2D1] border-b border-b-[#C7C1B2] flex w-full 
    flex-col px-10 py-4 max-md:max-w-full max-md:px-5"
  >
    <div
      class="justify-between items-start self-center flex w-full gap-5 max-md:gap-2 pr-6 
      max-md:max-w-full max-md:flex-wrap max-md:justify-center max-md:pr-2"
    >
      <router-link to="/cabinet"><img
        loading="lazy"
        src="/static/mintage.png"
        class="w-[160px] max-md:w-[140px]"
      /></router-link>
      <div
        class="items-start flex w-[560px] max-w-full pl-4 
        gap-5 mr-auto max-md:justify-center max-md:flex-wrap max-md:text-sm
        "
      >

            <router-link class="tab my-auto mt-2" :to="{ name: 'Tokens' }" :class="{ 'act': isActiveTab('Tokens') }">{{ $t('Menu.Tokens') }}</router-link>
            <router-link class="tab my-auto mt-2" :to="{ name: 'Transac' }" :class="{ 'act': isActiveTab('Transac') }">{{ $t('Menu.Transactions') }}</router-link>
            <router-link class="tab my-auto mt-2" :to="{ name: 'Refs' }" :class="{ 'act': isActiveTab('Refs') }">{{ $t('Menu.Refs') }}</router-link>
            <router-link class="tab my-auto mt-2" :to="{ name: 'InfoGeneral' }" :class="{ 'act': isActiveTab('Info') || isActiveTab('InfoGeneral') }">{{ $t('Menu.Info') }}</router-link>
            
      </div>
      <!-- Right Items -->
      <div
        class="items-start self-stretch flex max-w-full my-auto gap-5"
      >
      

        <div class="tab">
          <div v-if="!userdata.email" class="">
            <div class="spinner w-8 h-8"></div>
            </div>
          <div v-else>{{ userdata.email }}</div>
        </div>
        <router-link class="tab" :to="{ name: 'ProfileSettings' }" :class="{ 'act': isActiveTab('ProfileSettings') }">{{ $t('Menu.Profile') }}</router-link>
        
        <div class="relative my-auto" v-on:mouseleave="showModal = false" v-on:touchend="toggleModal" ref="modal">
          <div class="text-xl whitespace-nowrap my-auto border border-gray-700 rounded-lg px-4 py-2" v-on:mouseover="showModal = true"><span class="uppercase">{{ this.$i18n.locale }}</span></div>
          <div class="absolute right-0 top-full cursor-pointer w-48 rounded-xl shadow-xl bg-white z-50 text-left" v-show="showModal">
            <div class="p-3" role="menu" aria-orientation="vertical" aria-labelledby="options-menu">
              <button class="px-5 py-2" v-for="(language, index) in languages" :key="index" @click="changeLanguage(language.locale)">
                <img :src="language.flag" class="inline-block mr-3 rounded-full border-2 border-neutral-500">
                <span>{{ language.name }}</span>
              </button>
            </div>
          </div>
        </div>

        <router-link to="/exit" class="my-auto"><img src="/static/logout.svg"></router-link>  
        <!-- <button @click="doExit" class="my-auto"><img src="/static/logout.svg"></button>   -->

    </div>
  </div>

</div>
</template>

<script>
import UserService from "@/services/user.service";
import AuthService from "@/services/auth.service";

export default {
  data() {
    return {
      userdata: [],
      showModal: false,
      languages: [
        { name: 'English', locale: 'en', flag: '/static/flags/gb-eng.png' },
        { name: 'German', locale: 'de', flag: '/static/flags/de.png' },
        { name: 'French', locale: 'fr', flag: '/static/flags/fr.png' },
        { name: 'Russian', locale: 'ru', flag: '/static/flags/ru.png' },
        { name: 'Chinese', locale: 'cn', flag: '/static/flags/cn.png' },
      ],
    };
  },
  mounted() {
      UserService.getUserData().then(
        (response) => {
          this.userdata = response.data;
        },
        (error) => {
          this.$store.dispatch("auth/refresh").then(
                  (response) => {
                    console.log('Token refreshed, retrying receive data...');
                    UserService.getUserData().then(
                      (response) => {
                        this.userdata = response.data;
                      });
                  },
                  (error) => {
                    console.log('Refresh token failed, logging out...');
                    this.$router.push('/newpage')
                  }
          ); }
          // this.error =
          //   (error.response &&
          //     error.response.data &&
          //     error.response.data.message) ||
          //   error.message ||
          //   error.toString();
          //   if(this.error=='Unauthorized'){
          //     console.log('Unauthorized tyring...');
          //     this.$store.dispatch("auth/refresh", user);
          //   }
        
      );
      this.$i18n.locale = localStorage.getItem('locale') || 'cn';
  },
  methods: {
    isActiveTab(tabName) {
      return this.$route.name === tabName;
    },
    doExit() {
      // AuthService.logout();
      this.$router.push('/logout');
    },
    changeLanguage(languageCode) {
      this.$i18n.locale = languageCode;
      localStorage.setItem('locale', languageCode);
      this.showModal=false;
    },
    closeModalIfOutsideModal(event) {
      if (!this.$refs.modal.contains(event.target)) {
        this.showModal = false;
      }
    },
    toggleModal() {
      this.isModalVisible = !this.isModalVisible;
    },
  },
  // watch: {
  //   '$i18n.locale'(newLocale) {
  //     localStorage.setItem('locale', newLocale);
  //   },
  // },
};
</script>