import { createStore } from "vuex";
import { auth } from "./auth.module";

const store = createStore({
  modules: {
    auth,
  },
  state: {
    signupEmail: '',
    signinEmail: '',
  },
  mutations: {
    setSignupEmail(state, email) {
      state.signupEmail = email;
    },
    setSigninEmail(state, email) {
      state.signinEmail = email;
    },
  },
});

export default store;

// import Vuex from 'vuex';

// export default new Vuex.Store({
//   state: {
//     email: '',
//   },
//   mutations: {
//     signupEmail(state, email) {
//       state.email = email;
//     },
//   },
// });

// const store = createStore({
//   state: {
//     signupEmail: '',
//   },
//   mutations: {
//     setSignupEmail(state, email) {
//       state.signupEmail = email;
//     },
//   },
// });